<template>
    <v-card flat>
        <v-card-title>
            <span class="secondary--text font-weight-bold">Large file storage</span>
        </v-card-title>
        <v-card-text>
            <div class="d-flex">
                <v-alert type="info" text dense>
                    <strong>Changes will take effect after the application is restarted.</strong>
                </v-alert>
            </div>
            <v-skeleton-loader class="mx-auto" type="table" v-if="fetchingMountedStorageList"></v-skeleton-loader>
            <template v-else>
                <div class="d-flex align-center">
                    <v-btn
                        @click="
                            () => {
                                if (this.storageOptions.length === 0) this.fetchStorageOptions()
                                mountDialog = true
                            }
                        "
                        color="primary"
                        class="mr-2"
                        v-if="canMountStorage">
                        <v-icon left>add</v-icon>
                        <span class="font-weight-bold">attach</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-text-field
                        label="Search for an attached storage..."
                        autofocus
                        v-model="search"
                        clearable
                        hide-details
                        prepend-inner-icon="mdi-filter"
                        solo
                        flat
                        background-color="grey lighten-4"
                        dense
                        class="mr-2" />
                    <v-btn :loading="fetchingMountedStorageList" @click="fetchMountedStorageList" icon><v-icon>refresh</v-icon></v-btn>
                </div>
                <v-data-table
                    :items="mountedStorageList"
                    item-key="amid"
                    :headers="headers"
                    :search="search"
                    :options="tableOptions"
                    :footer-props="{ 'items-per-page-options': itemsPerPageOptions }">
                    <template v-slot:top="{ pagination, options, updateOptions }">
                        <v-data-footer
                            :pagination="pagination"
                            :options="options"
                            @update:options="updateOptions"
                            :itemsPerPageOptions="itemsPerPageOptions"
                            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
                            class="no-border" />
                    </template>
                    <template v-slot:no-data>
                        <span>No additional storage has been attached yet. Click attach to attach a new storage.</span>
                    </template>
                    <template v-slot:[`item.long_id`]="{ item }">
                        <span class="text-body-1">{{ item.long_id }}</span>
                    </template>
                    <template v-slot:[`item.quota_gib`]="{ item }">{{ item.quota_gib }} GiB</template>
                    <template v-slot:[`item.read_only`]="{ item }">
                        {{ item.read_only ? 'Read only' : 'Read/Write' }}
                    </template>
                    <template v-slot:[`item.mount_path`]="{ item }">
                        <code class="text-body-2">{{ item.mount_path }}</code>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div class="d-flex flex-row justify-end">
                            <copy-to-clipboard
                                :textToCopy="item.mount_path"
                                buttonClass="caption font-weight-bold"
                                tooltip="Copy path to clipboard"
                                :iconButton="true" />
                            <v-tooltip bottom v-if="canMountStorage">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        icon
                                        small
                                        color="secondary"
                                        @click="openEditMountDialog(item)"
                                        v-bind="attrs"
                                        v-on="on"
                                        :loading="editingMount === item.amid">
                                        <v-icon small>edit</v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit attached storage</span>
                            </v-tooltip>
                            <v-tooltip bottom v-if="canMountStorage">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon small color="secondary" @click="openDeleteDialog(item)" v-bind="attrs" v-on="on">
                                        <v-icon small>delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Remove attached storage</span>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
            </template>
            <v-dialog v-model="editMountDialog" :max-width="dialogMaxWidth" lazy-validation persistent>
                <v-card>
                    <v-card-title>
                        <span>Edit attached storage</span>
                    </v-card-title>
                    <v-card-text class="mb-0">
                        <v-form v-model="mountEditFormIsValid" ref="mountEditForm">
                            <v-text-field v-model="mountPoint" outlined dense :rules="[rules.required, rules.validChars, rules.unique]">
                                <template v-slot:label>Path</template>
                            </v-text-field>
                            <div class="mb-1">Storage will be accessible under</div>
                            <code class="d-flex">
                                {{ mountBasePath }}/
                                <b>{{ mountPoint }}</b>
                            </code>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-checkbox v-model="mountReadOnly" :disabled="selectedStorageOptionObject?.force_read_only">
                            <template v-slot:label>Read only mode</template>
                        </v-checkbox>
                        <v-spacer></v-spacer>
                        <v-btn text @click="closeEditMountDialog">Cancel</v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="sendPatchRequest()"
                            :disabled="!mountEditFormIsValid"
                            :loading="editingMount === selectedMount?.amid">
                            Edit
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="mountDialog" :max-width="dialogMaxWidth" lazy-validation persistent>
                <v-card>
                    <v-card-title>Attach storage</v-card-title>
                    <v-card-text>
                        <v-form v-model="mountFormIsValid" ref="mountCreateForm" class="pt-4">
                            <v-select
                                v-model="selectedStorageOption"
                                :items="storageOptions"
                                label="Select a storage option"
                                item-text="long_id"
                                item-value="afsid"
                                outlined
                                dense
                                :rules="[rules.required]">
                                <template v-slot:item="{ item, on, attrs }">
                                    <v-list-item two-line v-bind="attrs" v-on="on">
                                        <v-list-item-content>
                                            <v-list-item-title>{{ item.long_id }}</v-list-item-title>
                                            <v-list-item-subtitle>
                                                {{ item.quota_gib }} GiB
                                                <strong v-if="item.force_read_only">read only</strong>
                                                <span v-if="item.cancellation_timestamp">
                                                    <br />
                                                    Available until {{ item.last_availability_date | dateToHuman }}
                                                </span>
                                            </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>
                            <v-alert type="warning" text dense class="mt-3" v-if="alreadyMountedUnder">
                                This storage is already attached under
                                <code>{{ alreadyMountedUnder }}</code>
                            </v-alert>
                            <v-text-field
                                v-model="mountPoint"
                                outlined
                                hint="This field is required"
                                :rules="[rules.required, rules.validChars, rules.unique]"
                                dense>
                                <template v-slot:label>Path</template>
                            </v-text-field>
                            <div class="mb-1">Storage will be accessible under</div>
                            <code class="d-flex">
                                {{ mountBasePath }}/
                                <b>{{ mountPoint }}</b>
                            </code>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-checkbox v-model="mountReadOnly" :disabled="selectedStorageOptionObject?.force_read_only">
                            <template v-slot:label>Read only mode</template>
                        </v-checkbox>
                        <v-spacer></v-spacer>
                        <v-btn text @click="mountDialog = false">Cancel</v-btn>
                        <v-btn text color="primary" @click="sendPostRequest()" :disabled="!mountFormIsValid" :loading="addingMount">Add</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import eventBus from '@/components/EventBus'
import { sortBy } from 'lodash'

const CopyToClipboard = () => import('@/components/CopyToClipboard.vue')

export default {
    components: { CopyToClipboard },
    data() {
        return {
            fetchingMountedStorageList: false,
            storageTiers: {
                'afs-premium': 'Premium',
                'afs-to': 'Transaction optimized',
                'afs-hot': 'Hot',
                'afs-cool': 'Cool'
            },
            storageOptions: [],
            headers: [
                { text: 'Name', value: 'long_id', align: 'left' },
                { text: 'Access', value: 'read_only', align: 'left' },
                { text: 'Quota', value: 'quota_gib', align: 'left' },
                { text: 'Path', value: 'mount_path', align: 'right' },
                { text: 'Actions', value: 'actions', align: 'right' }
            ],
            itemsPerPageOptions: [25, 50, 100, -1],
            tableOptions: {
                page: 0,
                itemsPerPage: 25,
                pageStart: 1,
                pageStop: 25,
                itemsLength: 25
            },
            mountedStorageList: [],
            rules: {
                required: value => !!value || 'This field is required',
                validChars: p => /^[a-zA-Z0-9-_]*$/g.test(p) || 'Please use only letters, numbers, underscores and dashes',
                unique: value => !this.mountPoints.includes(value) || 'A storage is already mounted on that path'
            },
            search: '',
            selectedMount: null,
            editMountDialog: false,
            mountEditFormIsValid: true,
            selectedStorageOption: null,
            mountDialog: false,
            mountFormIsValid: true,
            mountPoint: '',
            mountReadOnly: false,
            addingMount: false,
            deletingMount: false,
            editingMount: null,
            dialogMaxWidth: '640px'
        }
    },
    computed: {
        ...mapGetters('spaceStore', ['isSpaceAdmin', 'isSpaceArchived']),
        canMountStorage() {
            return this.isSpaceAdmin
        },
        mountPoints() {
            const points = this.mountedStorageList.map(mount => this.trimMountPath(mount.mount_path))
            if (this.selectedMount) return points.filter(point => point !== this.trimMountPath(this.selectedMount.mount_path))
            return points
        },
        selectedStorageOptionObject() {
            return this.storageOptions.find(option => option.afsid === this.selectedStorageOption)
        },
        mountBasePath() {
            return this.storageOptions[0]?.base_path
        },
        alreadyMountedUnder() {
            if (!this.selectedStorageOptionObject) return false
            const { afsid } = this.selectedStorageOptionObject
            const mountPath = this.mountedStorageList.find(storage => storage.afsid === afsid)?.mount_path
            return mountPath
        }
    },
    methods: {
        async fetchStorageOptions() {
            const endpoint = `/spaces/${this.$route.params.sid}/afs`
            try {
                const { data } = await this.$axios.get(endpoint)
                this.storageOptions = sortBy(data, 'long_id')
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: error.response?.data?.debug || 'There was an error fetching available storage infromation',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
            this.fetchingMountedStorageList = false
        },
        async fetchMountedStorageList() {
            this.fetchingMountedStorageList = true
            const endpoint = `/spaces/${this.$route.params.sid}/mounts`
            try {
                const { data } = await this.$axios.get(endpoint)
                this.mountedStorageList = data
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: error.response?.data?.debug || 'There was an error fetching mounted storage infromation',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
            this.fetchingMountedStorageList = false
        },
        async sendDeleteRequest() {
            this.deletingMount = this.selectedMount.amid
            const endpoint = `/lfs/afs/mounts/${this.deletingMount}`
            try {
                await this.$axios.delete(endpoint)
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'There was an error deleting mounted storage infromation',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
            this.selectedMount = null
            this.deletingMount = false
            this.fetchMountedStorageList()
        },
        async sendPostRequest() {
            this.addingMount = true
            const endpoint = `/spaces/${this.$route.params.sid}/mounts`
            const body = {
                afsid: this.selectedStorageOption,
                mount_path: this.mountPoint,
                read_only: this.mountReadOnly
            }
            try {
                await this.$axios.post(endpoint, body)
                this.fetchMountedStorageList()
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'There was an error mounting storage',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
            this.addingMount = false
            this.mountDialog = false
            this.mountPoint = ''
            this.selectedStorageOption = null
            this.$refs.mountCreateForm.reset()
            this.mountReadOnly = false
        },
        async sendPatchRequest() {
            this.editingMount = this.selectedMount.amid
            const endpoint = `/lfs/afs/mounts/${this.editingMount}`
            const body = {}
            if (this.trimMountPath(this.selectedMount.mount_path) !== this.mountPoint) body.mount_path = this.mountPoint
            if (this.selectedMount.read_only !== this.mountReadOnly) body.read_only = this.mountReadOnly
            try {
                await this.$axios.patch(endpoint, body)
            } catch (error) {
                this.$store.dispatch('showSnackBar', {
                    snackBarText: 'There was an error editing mount settings',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'error'
                })
            }
            this.editingMount = null
            this.editMountDialog = false
            this.$refs.mountEditForm.reset()
            this.mountReadOnly = false
            this.fetchMountedStorageList()
        },
        async openEditMountDialog(mount) {
            if (this.storageOptions.length === 0) await this.fetchStorageOptions()
            this.selectedMount = mount
            this.selectedStorageOption = this.storageOptions.find(option => option.afsid === mount.afsid).afsid
            this.editMountDialog = true
        },
        closeEditMountDialog() {
            this.editMountDialog = false
            this.selectedMount = null
            this.selectedStorageOption = null
            this.mountPoint = ''
        },
        openDeleteDialog(mount) {
            this.selectedMount = mount
            this.$store.dispatch('showGlobalDialog', {
                dialogText: `Are you sure you would like to detach this storage?`,
                dialogAction: ['delete', 'cancel'],
                dialogTitle: 'Confirm detach'
            })
        },
        selectStorageOption(option) {
            this.selectedStorageOption = option
        },
        trimMountPath(path) {
            return path.replace(`${this.mountBasePath}/`, '')
        }
    },
    watch: {
        selectedStorageOptionObject(to) {
            if (to.force_read_only) this.mountReadOnly = true
        },
        selectedMount(to) {
            this.mountReadOnly = to?.read_only
            this.mountPoint = this.trimMountPath(to?.mount_path)
        }
    },
    created() {
        this.fetchMountedStorageList()
        eventBus.$on('global-dialog-accept', async () => {
            this.sendDeleteRequest()
        })
        eventBus.$on('global-dialog-decline', async () => {
            this.selectedMount = null
        })
    }
}
</script>
